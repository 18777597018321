import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FlashComponent from "../utils/FlashComponent";
import FormWrapper from "../utils/FormWrapper";

// { flash, requiresOTP, token }
export default function Form({ config, flash, token, user }) {
  const [flashMessage, setFlashMessages] = useState(
    flash ? Object.values(JSON.parse(flash))?.[0] : undefined
  );
  const [form, setForm] = useState({
    email: user?.email ?? "",
    password: "",
    // otp_attempt: requiresOTP ? "" : undefined,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleFormChange = (change) => setForm({ ...form, ...change });
  const handleMouseDownPassword = (e) => e.preventDefault();
  const handleMouseUpPassword = (e) => e.preventDefault();

  const inputPropsForPW = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          onMouseUp={handleMouseUpPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
  };

  const { email, password } = form;
  const disabled = !(form.email.length && form.password.length) || loading;

  return (
    <FormWrapper config={config}>
      <Box
        action="/users/sign_in"
        component="form"
        method="post"
        onSubmit={(e) => {
          setLoading(true);
          e.submit();
        }}
        role="form"
        sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <input type="hidden" name="authenticity_token" value={token} />
        {flashMessage && <FlashComponent value={flashMessage} />}
        <Typography align="center" sx={{ fontWeight: "bold" }} variant="h1">
          Sign In
        </Typography>
        <TextField
          autoComplete="username"
          fullWidth
          id="user_email"
          label="Email"
          onChange={(e) => handleFormChange({ email: e.target.value })}
          name="user[email]"
          size="small"
          type="email"
          value={email}
        />
        <TextField
          autoComplete="current-password"
          fullWidth
          id="user_password"
          label="Password"
          onChange={(e) => handleFormChange({ password: e.target.value })}
          name="user[password]"
          size="small"
          InputProps={{
            ...inputPropsForPW,
          }}
          type={showPassword ? "text" : "password"}
          value={password}
        />
        <Link href="/users/password/new" underline="none">
          <Typography variant="caption">Forgot your password?</Typography>
        </Link>
        <Button disabled={disabled} type="submit" variant="contained">
          {loading ? "Signing in..." : "Sign in"}
        </Button>
      </Box>
    </FormWrapper>
  );
}

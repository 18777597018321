/*-----------------------------------------------------------------------

  Colors

------------------------------------------------------------------------*/

// 1 = lightest, larger #s = darker

export const blueLight = "#F2F4F9";
export const blue1 = "#CEECFB";
export const blue2 = "#D0E5EF";
export const blue3 = "#97B8C9";
export const blue4 = "#8ba9ba";
export const blue6 = "#306379";
export const blueDark = "#2980B9";
export const blue8 = "#244E63";
export const blueAccent = "#15A2E4";
export const selected = "#3497FF";
export const blue9 = "#0B3766";
export const blue10 = "#2c1baf";
export const blue11 = "#1e90ff";

// blue9 is the dark blue for collab inventory badge

export const grey1 = "#FAFAFA";
export const grey1b = "#F0F0F0";
export const grey2 = "#DADADA";
export const grey2b = "#D3D3D3";
export const grey3 = "#d0d0d0";
export const grey3b = "#C9C9C9";
export const grey4 = "#999";
export const grey5 = "#888";
export const grey6 = "#777";
export const grey7 = "#666";
export const grey7b = "#555";
export const grey8 = "#444";
export const grey9 = "#333";
export const greyHeader = "#ECECEC";

export const green = "#72AA5A";
export const green1a = "#DCFBDD";
export const green1 = "#DCEED4";
export const green2 = "#C5EBB4";
export const green4 = "#3AAF52";
export const green7 = "#5B9044";

export const greenLightest = green2;
export const greenDark = "#379E51";
export const greenMuted = "#E7FCEC";

export const red0 = "#ffeaea";
export const red1 = "#ff9f9f";
export const red2 = "#f85757";
export const red3 = "#DB5858";
export const red4 = "#995656";
export const red5 = "#D0011B";
export const important = red3;

export const orange1 = "#FFE1B6";
export const orange4 = "#F8A326";
export const orange5 = "#F19917";
export const orangeDark = "#cd8124";
export const orangeDarker = "#e47823";
export const rental = "#FAA400";

export const yellow3 = "#FCF8E2";
export const yellow4 = "#fbeed5";
export const yellow5 = "#E6D8BE";
export const yellow6 = "#FFFBC9";
export const yellow6b = "#DAD681";
export const yellow7 = "#C1994D";

export const bodyTextColorPrimary = grey9;

// -------------------------------------- Variables

// Grey
export const grey = "#b9b9b9";
export const greyDark = "#444";
export const greyLight = "#F2F2F2";
export const greyMuted = "#999999";

// Blue
export const blue = "#15A2E4";
//$blue-light =    #15b9fc
//$blue-dark =     #2D596E

// Red
export const red = "#FF6C6C";
export const redDark = "#CA3333";

// Yellow
export const yellowLight = "#FCF8E3";
export const yellowLightBorder = "#FBEED5";
export const yellowDark = "#C09853";

// White
export const white = "#fff";

// Purple
export const purple = "#9564e2";
export const purple2 = "#800080";

// Pink
export const pink = "#c44a83";

// Brown
export const brown = "#924e31";

// Grey
// .color-grey
//   background-color = $grey
// .color-grey-dark
//   background-color = $grey-dark
// .color-grey-light
//   background-color = $grey-light
// .muted-background
//   background-color = $grey-muted
//   color = $white

// Blue
// .color-blue
//   background-color = $blue
// .color-blue-dark
//   background-color = $blue-dark

// Green
// .color-green
//   background-color = $green
// .color-green-dark
//   background-color = $green-dark
// .color-green-muted
//   background-color = $green-muted

// Red
// .color-red
//   background-color = $red
// .color-red-dark
//   background-color = $red-dark

// Orange
// .color-orange
//   background-color = $orange-4
// .color-orange-dark
//   background-color = $orange-dark

// Yellow
// .color-yellow-light
//   background-color = $yellow-light
// .color-yellow-light-border
//   background-color = $yellow-light-border
// .color-yellow-dark
//   background-color = $yellow-dark

// Contextual Colors
export const success = "#27AE60";
export const danger = "#C0392B";
//$muted
export const primary = "#3498DB";
export const info = "#34495E";
export const warning = "#E67E22";
export const inverse = "#2C3E50";

// Move status colors
export const complete = "#49a043";
export const incomplete = "#e32f2f";
export const inprogress = "#edaa2a";
export const unassigned = red3;

// Color Variables
export const colors = [
  orange4,
  blue,
  purple,
  green,
  red,
  pink,
  orangeDark,
  brown,
];
export const colorsNames = [
  "orange",
  "blue",
  "purple",
  "green",
  "red",
  "pink",
  "dark-orange",
  "brown",
];

/*------------------------------------------------------------------------

  Fonts

------------------------------------------------------------------------*/

export const fontBrandPrimary = "helvetica neue, helvetica, arial, sans-serif";
export const fontBrandSecondary =
  "helvetica neue, helvetica, arial, sans-serif";
export const fontFamilyBase = `${fontBrandPrimary} !default`;

// Sizes
export const baseFontSize = "14px";
export const fontSizeLarge = "18px";
export const fontSizeSmall = "10px";
export const fontSizeMini = "8px";

// Weights
export const fontWeightLightSecondary = 300;
export const fontWeightBoldSecondary = 700;

// Colors
export const fontColorBody = grey9;
export const linkColor = blueAccent;

/*-----------------------------------------------------------------------

    Grid stuff

------------------------------------------------------------------------*/

const bufferLg = "30px";
const bufferMd = "20px";
const bufferSm = "10px";

/*-----------------------------------------------------------------------
  
    Shadows
  
------------------------------------------------------------------------*/
// @mixin shadow-large()
//   +single-box-shadow(0, 2px, 10px, 0, rgba(0,0,0,0.20))
// @mixin shadow-small()
//   +single-box-shadow(0, 1px, 5px, 0, rgba(0,0,0,0.24))

/*------------------------------------------------------------------------
  
    Shadows
  
------------------------------------------------------------------------*/
// @mixin shadow-large()
//   +single-box-shadow(0, 2px, 10px, 0, rgba(0,0,0,0.20))
// @mixin shadow-small()
//   +single-box-shadow(0, 1px, 5px, 0, rgba(0,0,0,0.24))

/*-----------------------------------------------------------------------
  
    Misc
  
------------------------------------------------------------------------*/

export const borderRadiusDefault = "5px";
export const borderRadiusSmall = "3px";

export const zindexModal = 100;
export const zindexFixed = 50;

export const greyDisabledSelect = grey1;
export const greyDisabledText = greyMuted;

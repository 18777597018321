import React from "react";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { blueDark, primary } from "../../utils/base";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: primary,
          boxShadow: "none",
          "&:hover": {
            backgroundColor: blueDark,
          },
          "&.Mui-disabled": {
            backgroundColor: primary,
            color: "#fff",
            opacity: "65%",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          marginBottom: 0,
          marginTop: 2,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiInputBase-sizeSmall": {
            fontSize: 13,
            "& input": {
              lineHeight: "18px",
              textOverflow: "ellipsis",
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: primary,
          opacity: "80%",
          "&:hover": {
            opacity: "100%",
            textDecoration: "none",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: `${blueDark} !important`,
        },
        body2: {
          textWrap: "wrap",
        },
      },
    },
  },
  typography: {
    fontFamily: ["helvetica", "arial", "sans-serif"].join(","),
  },
});

export default function FormWrapper({ children, config }) {
  const regionFlag = config?.region ? (
    <span className="region-flag-unauthenticated">
      <img alt="" src={`/images/flags/flag-${config.region}.png`} />
    </span>
  ) : undefined;

  return (
    <ThemeProvider theme={theme}>
      <div className="controls">
        <div className="form-wrapper center box">
          <Box className="box-content">
            <img
              className="login-logo"
              src="/images/Cohealo-Logotype-Small-150.png"
              alt="Cohealo logotype small 150"
            />
            {regionFlag}
            <div id="flash-messages" />
            {children}
          </Box>
        </div>
      </div>
    </ThemeProvider>
  );
}

// const submitOtp = (e) => {
//   e.preventDefault();

//   setErrors({ otp_attempt: form.otp_attempt?.length === 6 });

//   if (form.otp_attempt) {
//     setLoading(true);
//     axios
//       .post(
//         "/users/sign_in/otp",
//         {
//           authenticity_token: formToken,
//           user: form,
//         },
//         {
//           headers: { "Content-Type": "application/json" },
//         }
//       )
//       .then((res) => {
//         const hasMsg = handleFlashFromResponse(res.headers);

//         // OPTIMIZE:
//         //   rails for some reason cannot use render/head methods to persist
//         //   authentication for future requests. This means we must use render_with
//         //   which will load the layout first before entering this block.
//         //   So, the sign in layout is redudnantly loaded again with `.reload`.
//         //   Optimally, we should just redirect via the header info, but that is not
//         //   happening here.
//         //
//         // if (res.headers.location) window.location.replace(res.headers.location);
//         if (!hasMsg) {
//           window.location.reload();
//         } else {
//           setLoading(false);
//         }
//       })
//       .catch((res) => {
//         console.log(res);
//         // 401 - Unauthorized
//         if (res.response.status === 410) {
//           handleFlashFromResponse(res.response.headers);
//           handleFormChange({ password: "", otp_attempt: undefined });
//         }
//         setLoading(false);
//       });
//   }
// };

// {form.otp_attempt !== undefined ? (
//   <OtpContent
//     disabled={
//       !(form.email.length && form.password.length) || loading
//     }
//     errors={errors}
//     flash={
//       typeof flashMessage === "string" ? flashMessage : undefined
//     }
//     form={form}
//     onChange={handleFormChange}
//     onSubmit={submitOtp}
//   />
// )}

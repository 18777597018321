import React from "react";
import Form from "./Form";
export default {
  component: Form,
  decorators: [
    (Story) => (
      <div id="sessions-new-page">
        <div className="container">
          <div id="content">
            <div id="content-wrapper">
              <div className="span12">
                <Story />
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    // withRequestContext,
  ],
  title: "Sessions/OTP/Form",
};

// Ensure this matches devise.en.yml as this could change
const deviseFlashMap = {
  failure: {
    already_authenticated: "",
    inactive: "Your account was not activated yet.",
    invalid: "Invalid email or password.",
    invalid_token: "Invalid authentication token.",
    last_attempt: "You have one more attempt before your account is locked.",
    locked: "locked",
    not_found_in_database: "Invalid email or password.",
    timeout: "Your session expired, please sign in again to continue.",
    unauthenticated: "You need to sign in or sign up before continuing.",
    unconfirmed: "You have to confirm your account before continuing.",
  },
  otp: {
    expired: "otp_expired",
  },
  passwords: {
    send_instructions: "send_instructions",
  },
  unlocks: {
    send_instructions: "send_instructions",
    unlocked: "unlocked",
  },
};

const Template = (args) => <Form {...args} />;

export const Default = Template.bind({});
Default.args = {
  // token: REAL_CAPI_TOKEN.length ? REAL_CAPI_TOKEN : "storybook_token",
  // user: adminUser,
};

export const Otp = Template.bind({});
Otp.args = {
  ...Default.args,
  requiresOTP: true,
};

export const WithFlash = Template.bind({});
WithFlash.args = {
  ...Default.args,
  flash: JSON.stringify({ alert: deviseFlashMap.unlocks.unlocked }),
};

import React, { useCallback, useState } from "react";
import axios from "axios";

import { yellowDark, yellowLight } from "../../utils/base";
import { growl } from "../../utils/alert";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FlashComponent from "../utils/FlashComponent";
import FormWrapper from "../utils/FormWrapper";

// { flash, requiresOTP, token }
export default function Form({ config, flash, token, user }) {
  const [flashMessage, setFlashMessages] = useState(
    flash ? Object.values(JSON.parse(flash))?.[0] : undefined
  );
  const [form, setForm] = useState({
    email: user?.email ?? "",
    // password: "",
    otp_attempt: user?.otp_attempt ?? "",
  });
  const [loading, setLoading] = useState(false);

  const handleFormChange = (change) => setForm({ ...form, ...change });
  const handleResendEmail = useCallback(() => {
    axios
      .get("/users/sign_in/otp_resend", {
        headers: { "Content-Type": "application/json" },
        params: { email: form.email },
      })
      .then((res) => {
        growl(
          "Email was sent",
          `Please check your spam folder or wait 5 minutes before resending.`,
          {
            enableClose: true,
          }
        );
      })
      .catch((res) => {
        console.log(res);
        // unauthorized
        growl(
          "Error",
          "There was an issue resending your verification code. Please refresh and try again or contact Cohealo Support at help@cohealo.com",
          {
            enableClose: true,
            icon: "remove",
            status: "danger",
          }
        );
      });
  }, [form.email]);

  const disabled =
    loading || (!form.email.length && form.otp_attempt.length !== 6);

  return (
    <FormWrapper config={config}>
      <Box
        action="/users/sign_in/otp"
        component="form"
        method="post"
        onSubmit={(e) => {
          setLoading(true);
          e.submit();
        }}
        role="form"
        sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <input type="hidden" name="authenticity_token" value={token} />
        <input type="hidden" name="user[email]" value={form.email} />
        {flashMessage && <FlashComponent value={flashMessage} />}
        <Typography align="center" sx={{ fontWeight: "bold" }} variant="h1">
          Verification Code
        </Typography>
        <Box
          sx={{
            backgroundColor: yellowLight,
            borderRadius: "7px",
            color: yellowDark,
            p: "10px",
          }}
        >
          <Typography variant="body2">
            For added security, please enter the verification code that has been
            sent to your email.
          </Typography>
        </Box>
        <TextField
          fullWidth
          id="user_otp_attempt"
          label="Enter verification code"
          onChange={(e) => {
            if (e.target.value.length <= 6)
              handleFormChange({ otp_attempt: e.target.value });
          }}
          name="user[otp_attempt]"
          size="small"
          value={form.otp_attempt}
        />
        <Button disabled={disabled} type="submit" variant="contained">
          {loading ? "Verifying..." : "Verify"}
        </Button>
        <Typography variant="caption">
          Did not receive a email? Please check your spam folder or&nbsp;
          <Link
            id="resend_otp"
            onClick={handleResendEmail}
            sx={{ cursor: "pointer" }}
            underline="hover"
          >
            click here to resend
          </Link>
          .
        </Typography>
      </Box>
    </FormWrapper>
  );
}

import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import EmailIcon from "@mui/icons-material/Email";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { yellowDark, yellowLight } from "../../utils/base";

export default function FlashComponent({ value }) {
  const message =
    typeof value === "string"
      ? value
      : value?.header?.length
      ? value.header
      : value?.message?.length
      ? value.message
      : "Error";

  return (
    <Box
      sx={{
        backgroundColor: yellowLight,
        borderRadius: "7px",
        color: yellowDark,
        display: "flex",
        justifyContent: "center",
        p: "15px 10px",
        gap: "4px",
      }}
    >
      {renderMessage(message)}
    </Box>
  );
}

// Dev: Should generally allow the default Devise flash messages render default
// However, please override with custom styling as need for things that require
// extra HTML or alternative icons here.
const renderMessage = (message) => {
  const iconSx = { fontSize: 14 };

  switch (message) {
    case "locked": {
      return [
        <LockIcon key="locked-lockicon" sx={iconSx} />,
        <Typography key="locked-text" variant="body2">
          Your account is locked due to too many failed login attempts.
          <br />
          <a href="/users/unlock/new">Click to unlock</a>, or contact Cohealo
          support at <a href="tel:617-855-1718">617-855-1718</a>&nbsp;or&nbsp;
          <a href="mailto:help@cohealo.com">help@cohealo.com</a>
        </Typography>,
      ];
    }
    case "otp_expired": {
      return [
        <WarningAmberIcon key="otp_expired-warn" sx={iconSx} />,
        <Typography key="otp_expired-text" variant="body2">
          Your verification code has expired. Please sign in again.
          <br />
          If you are having additional issues, Please contact Cohealo support.
          <br />
          <a href="tel:617-855-1718">617-855-1718</a>&nbsp;or&nbsp;
          <br />
          <a href="mailto:help@cohealo.com">help@cohealo.com</a>
        </Typography>,
      ];
    }
    case "send_instructions": {
      return [
        <EmailIcon key="send_instructions-email" sx={iconSx} />,
        <Typography key="otp_expired-text" variant="body2">
          We sent you an email with a link to change your password.
        </Typography>,
      ];
    }
    case "unlocked": {
      return [
        <LockIcon key="unlocked-lockopen" sx={iconSx} />,
        <Typography key="unlocked-text" variant="body2">
          Your account has been unlocked successfully. Please sign in to
          continue.
        </Typography>,
      ];
    }
    default: {
      return [
        <WarningAmberIcon key="default-icon" sx={iconSx} />,
        <Typography key="default-text" variant="body2">
          {message}
        </Typography>,
      ];
    }
  }
};
